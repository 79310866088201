import { Vue, Options } from 'vue-class-component';
import {
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonText,
    IonTextarea,
} from '@ionic/vue';
import {
    IResult,
    PlantReferenceDTO,
    SupplierAcceptOfferDTO,
    SupplierCalendarDayDTO,
    SupplierOfferListDTO,
} from '@/../client-generator/generated-clients/client';
import { SET_APP_LOADING } from '@/core/store/action-types';
import fruttaMeClient from '@/core/api/fruttame-service';
import { getColtureName } from '@/core/utilities/utils';
import { mapState } from 'vuex';
import { PAGE_SALES_PLANNING } from '@/core/router/pages';

@Options({
    name: 'SaleConfirmation',
    components: {
        IonIcon,
        IonItem,
        IonLabel,
        IonList,
        IonText,
        IonTextarea,
    },
    computed: {
        ...mapState(['currentPlant']),
    },
    props: {
        selectedDay: {
            type: SupplierCalendarDayDTO,
            required: true,
        },
        offer: {
            type: SupplierOfferListDTO,
            required: true,
        },
        closePanel: {
            type: Function,
            required: false,
        },
    },
})
export default class SaleConfirmation extends Vue {
    currentPlant!: PlantReferenceDTO;
    offer!: SupplierOfferListDTO | null;
    selectedDay!: SupplierCalendarDayDTO | null;
    closePanel!: Function;
    getColtureName = getColtureName;
    wasSent = false;
    showScheduledTransports = false;

    toogleBox() {
        this.showScheduledTransports = !this.showScheduledTransports;
    }
    doAnnulla(refreshParentList = false) {
        this.closePanel(refreshParentList);
    }
    gotoSalesPlanning() {
        this.closePanel();
        this.$router.push({ name: PAGE_SALES_PLANNING });
    }
    async doAcceptOffer() {
        if (
            !this.currentPlant ||
            !this.selectedDay ||
            !this.selectedDay?.date ||
            this.wasSent
        )
            return;
        this.$store.dispatch(SET_APP_LOADING, true);
        try {
            const acceptOffer = new SupplierAcceptOfferDTO();

            // tricky question from the web part
            // if you pass pure ISO date, you will get format date error
            const _formatedDate = new Date(this.selectedDay?.date);
            _formatedDate.setHours(10, 0);
            // setup correct fields from web part
            acceptOffer.dealProposalId = this.selectedDay?.proposalId;
            acceptOffer.plantId = this.currentPlant.plantId;
            acceptOffer.date = _formatedDate;
            acceptOffer.price = this.selectedDay?.price;
            acceptOffer.unitString = this.selectedDay?.unitString;

            await fruttaMeClient.accept(acceptOffer);
            this.wasSent = true;
        } catch (error) {
            console.error(error);
            this.$messageService.error(
                (error.data as IResult).errorMessage ?? this.$t('general.error')
            );
        } finally {
            this.$store.dispatch(SET_APP_LOADING, false);
        }
    }
}
