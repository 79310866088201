import { Vue, Options } from 'vue-class-component';
import { PAGE_DASHBOARD } from '@/core/router/pages';
import fruttaMeClient from '@/core/api/fruttame-service';
import { SET_APP_LOADING } from '@/core/store/action-types';
import SaleConfirmation from '@/views/components/SaleConfirmation/SaleConfirmation.vue';
import {
    FarmingType,
    PlantReferenceDTO,
    SupplierCalendarDayDTO,
    SupplierOfferListDTO,
} from 'client-generator/generated-clients/client';
import { mapState } from 'vuex';
import { modalController } from '@ionic/vue';

type CalendarDay = SupplierCalendarDayDTO & {
    isSelected?: boolean;
};

@Options({
    name: 'Calendar',
    computed: {
        ...mapState(['currentPlant']),
    },
    components: {
        SaleConfirmation,
    },
})
export default class Calendar extends Vue {
    currentPlant!: PlantReferenceDTO;
    offer: SupplierOfferListDTO | null = null;
    SaleConfirmation = SaleConfirmation;

    currentDate = new Date();
    displayDate = new Date();

    calendarData: SupplierCalendarDayDTO[] | null | undefined = null;
    selectedDay: CalendarDay | null = null;
    panelAcceptOffer: HTMLIonModalElement | null = null;

    get firstDayClass() {
        let firstDayClass = '';

        if (this.calendarData && this.calendarData.length) {
            const firstDay = this.calendarData[0];
            const date = new Date(firstDay.date as Date);
            const shortDayName = date
                .toLocaleDateString('en-us', {
                    weekday: 'short',
                })
                .toLowerCase();
            firstDayClass = `calendar__day-${shortDayName}`;
        }

        return firstDayClass;
    }

    get monthAndYear() {
        return this.displayDate.toLocaleDateString('it-IT', {
            month: 'long',
            year: 'numeric',
        });
    }

    get showPrevious() {
        return this.displayDate > this.currentDate;
    }

    mounted() {
        try {
            this.offer = JSON.parse(this.$route.params.offer as string);
            this.fetchData();
        } catch (error) {
            console.log('Calendar: no offer data, redirecting to dashboard');
            this.$router.push({
                name: PAGE_DASHBOARD,
            });
        }
    }

    async fetchData() {
        if (!this.offer) {
            return;
        }

        this.$store.dispatch(SET_APP_LOADING, true);

        try {
            const result = await fruttaMeClient.calendar(
                this.displayDate.getMonth() + 1,
                this.displayDate.getFullYear(),
                this.offer.productCategoryId as number,
                this.offer.varietyId as number,
                this.offer.manufactureId as number,
                this.offer.farmingType as unknown as FarmingType,
                this.currentPlant.plantId as number
            );

            this.calendarData = result.data?.map((d) => {
                if (d && d.date) {
                    const shortDayName = new Date(d.date)
                        .toLocaleDateString('en-us', {
                            weekday: 'short',
                        })
                        .toLowerCase();

                    if (shortDayName !== 'sun') return d;
                }

                return null;
            }) as SupplierCalendarDayDTO[];
        } catch (error) {
            let errorMessage = '';

            switch (error.status) {
                default:
                    errorMessage = this.$t('general.apiError');
                    break;
            }

            this.$messageService.error(errorMessage);
        } finally {
            this.$store.dispatch(SET_APP_LOADING, false);
        }
    }

    getDayNo(day: Date) {
        return day.getDate();
    }

    isPastDay(dayDate: SupplierCalendarDayDTO) {
        return (
            (dayDate.date as Date).setHours(0, 0, 0, 0) <
            this.currentDate.setHours(0, 0, 0, 0)
        );
    }

    goBackToList() {
        this.$router.push({
            name: PAGE_DASHBOARD,
        });
    }

    setPreviousMonth() {
        this.selectedDay = null;

        this.displayDate = new Date(
            this.displayDate.setMonth(this.displayDate.getMonth() - 1)
        );

        this.fetchData();
    }

    setNextMonth() {
        this.selectedDay = null;

        this.displayDate = new Date(
            this.displayDate.setMonth(this.displayDate.getMonth() + 1)
        );

        this.fetchData();
    }

    handleCellClick(day: SupplierCalendarDayDTO) {
        if (this.selectedDay) {
            this.selectedDay.isSelected = false;
        }
        this.selectedDay = day;
        this.selectedDay.isSelected = true;
    }

    generateCellClasses(day: CalendarDay) {
        return {
            'is--disabled': this.isPastDay(day),
            'is--disabled-day': !day.proposalId,
            'has--extra-transports':
                (day.scheduledTransportsCount as number) > 0,
            'is--selected': day.isSelected,
        };
    }

    async openAcceptOffer() {
        this.panelAcceptOffer = await modalController.create({
            component: SaleConfirmation,
            backdropDismiss: true,
            cssClass: 'panel highest',
            componentProps: {
                offer: this.offer,
                selectedDay: this.selectedDay,
                closePanel: this.closePanel,
            },
        });

        this.panelAcceptOffer.present();
    }

    closePanel(refreshList = false) {
        if (this.panelAcceptOffer) {
            this.panelAcceptOffer.dismiss();
        }
        if (refreshList) {
            this.selectedDay = null;
            this.fetchData();
        }
    }
}
