<template>
    <ion-page>
        <TheHeader :go-back="goBackToList" :has-extra-back-button="true" />

        <ion-content :fullscreen="true">
            <div
                class="container"
                :class="{ 'has--sticky-footer': selectedDay !== null }"
            >
                <h1 class="fs-20 mt-3 mb-2">
                    {{ $t("calendar.selectLoadDate") }}
                </h1>
                <p class="mt-0 fs-14">{{ $t("calendar.pricesInEuro") }}</p>

                <ion-card v-if="offer" class="card-custom">
                    <ion-card-header>
                        <ion-card-title class="d-flex align-items-start">
                            <img
                                :src="offer.productIconUrl"
                                alt="x"
                                class="mr-3"
                            />
                            <div>
                                <h1 class="m-0 fs-20">
                                    {{ offer.translatedName }}
                                </h1>
                                <div class="badge-list">
                                    <span
                                        class="
                                            badge
                                            text-lowercase text-white
                                            default-orange
                                        "
                                        :style="`background-color: ${offer.manufactureColor}`"
                                    >
                                        {{ offer.manufacture }}
                                    </span>
                                    <span
                                        class="
                                            badge badge-outline
                                            text-lowercase
                                        "
                                    >
                                        {{ offer.variety }}
                                    </span>
                                </div>
                            </div>
                        </ion-card-title>
                    </ion-card-header>
                </ion-card>

                <div class="calendar">
                    <div class="calendar__head">
                        <button
                            v-if="showPrevious"
                            class="calendar__prev"
                            @click="setPreviousMonth()"
                        >
                            <font-awesome-icon
                                :icon="['fal', 'chevron-left']"
                                size="lg"
                            />
                        </button>
                        <div class="calendar__month">
                            <strong>{{ monthAndYear }}</strong>
                        </div>
                        <button class="calendar__next" @click="setNextMonth()">
                            <font-awesome-icon
                                :icon="['fal', 'chevron-right']"
                                size="lg"
                            />
                        </button>
                    </div>
                    <div class="calendar__body">
                        <div class="calendar__day">
                            {{ $t("calendar.mon") }}
                        </div>
                        <div class="calendar__day">
                            {{ $t("calendar.tue") }}
                        </div>
                        <div class="calendar__day">
                            {{ $t("calendar.wed") }}
                        </div>
                        <div class="calendar__day">
                            {{ $t("calendar.thu") }}
                        </div>
                        <div class="calendar__day">
                            {{ $t("calendar.fri") }}
                        </div>
                        <div class="calendar__day">
                            {{ $t("calendar.sat") }}
                        </div>

                        <template
                            v-for="(day, index) in calendarData"
                            :key="`day-${index}${
                                day ? `-${day.date.getTime()}` : null
                            }`"
                        >
                            <button
                                v-if="day"
                                class="calendar__cell"
                                :class="[
                                    index === 0 ? firstDayClass : null,
                                    generateCellClasses(day),
                                ]"
                                @click="
                                    !isPastDay(day) && day.proposalId
                                        ? handleCellClick(day)
                                        : null
                                "
                            >
                                <span class="calendar__day-no">
                                    {{ getDayNo(day.date) }}
                                </span>
                                <strong
                                    v-if="day.proposalId"
                                    class="text-primary fs-12"
                                >
                                    {{ day.price }}
                                </strong>
                                <font-awesome-icon
                                    v-else-if="!isPastDay(day)"
                                    icon="ban"
                                    class="fs-12"
                                />
                            </button>
                        </template>
                    </div>
                </div>
            </div>
            <!-- .container -->
            <footer
                v-if="selectedDay"
                class="calendar-footer"
                :class="{
                    'has--extra-transports':
                        selectedDay.scheduledTransportsCount > 0,
                }"
            >
                <p class="mt-0">
                    <time>
                        {{
                            selectedDay.date.toLocaleDateString("it-IT", {
                                day: "numeric",
                                month: "long",
                            })
                        }}
                    </time>
                    -
                    <strong class="text-primary mr-1">{{
                        selectedDay.price
                    }}</strong>
                    <span>{{ selectedDay.unitString }}</span>
                </p>
                <button
                    class="btn btn-success w-100 mb-3"
                    @click="openAcceptOffer()"
                >
                    {{ $t("general.continue") }}
                </button>
            </footer>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./Calendar.ts"></script>

<style lang="scss" src="./Calendar.scss"></style>
