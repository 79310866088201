<template>
    <div class="box-sale-confrimation" v-if="!wasSent">
        <div class="p-3">
            <h1 class="fs-18 mt-1">
                <span
                    class="cursor-pointer float-right fs-20 pr-3 d-inlineblock"
                    @click="doAnnulla()"
                    >&times;</span
                >
                {{ $t("saleConfirmation.title") }}&nbsp;
            </h1>
            <div class="m-0 scrolling-content">
                <div v-if="offer">
                    <div class="d-flex align-items-start text-dark mb-3">
                        <div>
                            <img
                                :src="offer.productIconUrl"
                                :alt="offer.name"
                            />
                        </div>
                        <div class="w-100 pl-2">
                            <h1 class="fs-18 my-0">
                                {{ offer.name }}
                            </h1>
                            <span
                                class="
                                    badge
                                    text-lowercase
                                    mr-1
                                    text-white
                                    default-orange
                                "
                                :style="
                                    'background-color:' +
                                    offer.manufactureColor +
                                    ';'
                                "
                            >
                                {{ offer.manufacture }}
                            </span>
                            <span class="badge badge-outline">
                                {{ offer.productVariety }}
                            </span>
                        </div>
                    </div>
                    <div class="d-flex border-bottom fs-13 pt-2 pb-2">
                        <span class="float-left">{{
                            $t("general.plant")
                        }}</span>
                        <strong class="text-nowrap text-right w-100">{{
                            currentPlant.name
                        }}</strong>
                    </div>
                    <div class="d-flex border-bottom fs-13 pt-2 pb-2">
                        <span>{{ $t("general.production") }}</span>
                        <strong class="text-nowrap text-right w-100">{{
                            getColtureName(offer.farmingType)
                        }}</strong>
                    </div>
                    <div class="d-flex border-bottom fs-13 pt-2 pb-2">
                        <span>{{ $t("general.transport") }}</span>
                        <strong class="text-nowrap text-right w-100">{{
                            offer.transport
                        }}</strong>
                    </div>
                    <div class="d-flex border-bottom fs-13 pt-2 pb-2">
                        <span>{{ $t("general.quantity") }}</span>
                        <strong class="text-nowrap text-right w-100"
                            >{{ offer.quantity || "- " }}Kg</strong
                        >
                    </div>
                    <div class="bg-light mt-2 p-2 mb-2">
                        <div class="d-flex fs-16 p-2">
                            <span>{{ $t("general.price") }}*</span>
                            <strong class="text-nowrap text-right w-100"
                                >{{ selectedDay.price
                                }}{{ selectedDay.unitString }}</strong
                            >
                        </div>
                        <div class="d-flex fs-16 p-2">
                            <span class="text-nowrap">{{
                                $t("general.loadDate")
                            }}</span>
                            <span class="text-nowrap text-right w-100">{{
                                new Date(selectedDay.date).toLocaleString(
                                    undefined,
                                    {
                                        weekday: "short",
                                        month: "long",
                                        day: "2-digit",
                                        year: "numeric",
                                    }
                                )
                            }}</span>
                        </div>
                    </div>
                    <div
                        v-if="selectedDay.scheduledTransportsCount > 0"
                        class="box-scheduled-transports p-2 fs-11"
                        @click="toogleBox()"
                    >
                        <!-- scheduledTransports?: string[] | undefined; -->
                        {{
                            $t("saleConfirmation.scheduledTransportsCount", {
                                scheduledTransportsCount:
                                    selectedDay.scheduledTransportsCount,
                            })
                        }}
                        <ul v-if="showScheduledTransports" class="pb-0 mb-0">
                            <li
                                v-for="(
                                    scheduledTransport, index
                                ) in selectedDay.scheduledTransports"
                                :key="index"
                            >
                                {{ scheduledTransport }}
                            </li>
                        </ul>
                    </div>
                    <span class="fs-11"
                        >* {{ $t("saleConfirmation.note") }}</span
                    >
                </div>
            </div>
        </div>
        <div class="d-flex w-100 p-3 position-absolute bottom-0 bg-white">
            <ion-button
                color="dark"
                fill="outline"
                class="w-100"
                @click="doAnnulla()"
            >
                {{ $t("general.close") }}
            </ion-button>
            <ion-button color="primary" class="w-100" @click="doAcceptOffer()">
                {{ $t("saleConfirmation.saleConfirmBtn") }}
            </ion-button>
        </div>
    </div>

    <div v-if="wasSent" class="text-center">
        <div class="p-3">
            <div class="fs-18 mb-3">
                <span
                    class="cursor-pointer float-right fs-20 pr-3 d-inlineblock"
                    @click="doAnnulla(true)"
                    >&times;</span
                >&nbsp;
            </div>
            <div class="p-3">
                <font-awesome-icon
                    :icon="['fal', 'check-circle']"
                    class="fs-50 text-primary"
                />
                <h3 class="fs-18">
                    {{ $t("saleConfirmation.saleConfirmTitle") }}
                </h3>
                <div class="text-center mb-3">
                    {{ $t("saleConfirmation.saleConfirmDesc") }}
                </div>
                <div class="text-center mb-3">
                    <ion-button
                        color="dark"
                        fill="outline"
                        @click="gotoSalesPlanning()"
                    >
                        {{ $t("saleConfirmation.trackOrderBtn") }}
                    </ion-button>
                </div>
            </div>
        </div>

        <div class="position-absolute bottom-0 p-3 w-100">
            <ion-button color="primary" class="w-100" @click="doAnnulla(true)">
                {{ $t("saleConfirmation.backToCalendar") }}
            </ion-button>
        </div>
    </div>
</template>

<script lang="ts" src="./SaleConfirmation.ts"></script>
<style lang="scss" src="./SaleConfirmation.scss"></style>
